
















import { Component, Prop, Vue } from 'vue-property-decorator';
import BackButton from '@/components/ui/BackButton.vue';

import { mapState } from 'vuex';

interface IArticle {
    title: string;
    publishDate: string;
    coverUrl: string;
    author: string;
    body: string;
}

@Component({
    components: {
        BackButton
    },
    computed: {
        ...mapState(['article'])
    }
})
export default class ArticleView extends Vue {
    article!: { current: IArticle };

    /**
     * Get the current article
     */
    get current(): IArticle {
        return this.article.current;
    }

    /**
     * Get Article Title
     */
    get title(): string {
        return this.current.title || 'Default Title';
    }

    get publishDate(): string {
        return new Date(this.current.publishDate).toDateString();
    }

    get articleBgAttrs(): object {
        return {
            class: 'dn-article__header-bg',
            style: `background-image: url(${this.current.coverUrl})`
        }
    }
    
}
